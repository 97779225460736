import { ThemeService } from '@/services/ThemeService';

export default {
  async fetchThemeData({ commit }) {
    const themeData = await ThemeService.getStoryBookThemes();
    commit('SET_THEME_INFO', themeData);
  },
  setIsDataLoaded({ commit }, isDataLoaded) {
    commit('SET_IS_DATA_LOADED', isDataLoaded);
  },
};
