import { LoggingService } from '@memllc/component-library';
import Cookies from 'js-cookie';
import { SERVICE_CONSTANTS } from './Constants';

export class BrowserStorageService {
  static getCookieValueByCookieName(cookieName) {
    return Cookies.get(cookieName) || null;
  }

  static writeCookie(cookieName, cookieValue, cookieConfig) {
    if (cookieConfig) {
      Cookies.set(cookieName, cookieValue, cookieConfig);
    } else {
      Cookies.set(cookieName, cookieValue);
    }
  }

  static deleteCookieByCookieName(cookieName) {
    Cookies.remove(cookieName);
  }

  static getLocalStorageItemByKey(key) {
    const itemAsString = localStorage.getItem(key);

    if (!itemAsString) return null;

    const item = JSON.parse(itemAsString);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      LoggingService.info('The requested local storage value has expired.');
      return null;
    }

    return item.value;
  }

  static writeNewLocalStorageItemWithExpiration(key, value, timeToLive = SERVICE_CONSTANTS.EXPIRY_24_HOUR) {
    const now = new Date();

    if (!Array.isArray(value) && typeof value === 'object') {
      value.uniqueId = new Date().valueOf();
    }

    const itemToStore = {
      value,
      expiry: now.getTime() + timeToLive,
    };

    localStorage.setItem(key, JSON.stringify(itemToStore));
  }

  static pushOntoLocalStorageArrayByKey(key, newItem, timeToLive) {
    const existingLocalStorage = this.getLocalStorageItemByKey(key);
    newItem.uniqueId = new Date().valueOf();

    if (!existingLocalStorage) {
      this.writeNewLocalStorageItemWithExpiration(key, [newItem], timeToLive);
      return;
    }

    let newLocalStorageArray = [];

    if (!Array.isArray(existingLocalStorage)) {
      LoggingService.error('Error pushing to Local Storage');
    } else {
      newLocalStorageArray = existingLocalStorage;
    }

    newLocalStorageArray.push(newItem);

    this.writeNewLocalStorageItemWithExpiration(key, newLocalStorageArray, timeToLive);
  }

  static deleteLocalStorageItemByKeyAndUniqueId(key, uniqueId) {
    const existingLocalStorage = this.getLocalStorageItemByKey(key);
    if (!existingLocalStorage) return null;

    if (!Array.isArray(existingLocalStorage)) {
      localStorage.removeItem(key);
    } else {
      const indexToDelete = existingLocalStorage.findIndex((i) => i.uniqueId === uniqueId);
      existingLocalStorage.splice(indexToDelete, 1);
      this.writeNewLocalStorageItemWithExpiration(key, existingLocalStorage, SERVICE_CONSTANTS.EXPIRY_24_HOUR);
    }
  }

  static deleteLocalStorageItemByKey(key) {
    const existingLocalStorage = this.getLocalStorageItemByKey(key);
    if (!existingLocalStorage) return null;

    localStorage.removeItem(key);
  }

  static backupStoreCartItemsToLocalStorage(cartItems) {
    this.writeNewLocalStorageItemWithExpiration(SERVICE_CONSTANTS.LOCAL_STORAGE_KEYS.SHOPPING_CART, cartItems);
  }
}
