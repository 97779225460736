<template>
  <footer class="page-footer">
    <section class="page-footer__content">
      <img
        v-if="currentLocale.value === 'fr'"
        src="../assets/images/icons/Dignity_EDR_web_FRENCH.svg"
        :alt="LocalizationService.localize('DignityMemorial')"
        class="page-footer__content__logo"
      />
      <img
        v-else
        src="../assets/images/icons/Dignity_EDR_web.svg"
        :alt="LocalizationService.localize('DignityMemorial')"
        class="page-footer__content__logo"
      />
      <nav class="page-footer__content__navigation" :aria-label="LocalizationService.localize('FooterNavigation')">
        <ul class="horizontal-list">
          <!--
          <li class="pipe">
            <router-link to="/contact-us" class="page-footer__link">{{
              LocalizationService.localize('ContactUs')
            }}</router-link>
          </li>
          -->
          <li class="pipe">
            <a href="https://www.sci-corp.com/terms-of-service" class="page-footer__link">{{
              LocalizationService.localize('TermsOfService')
            }}</a>
          </li>
          <li>
            <a href="https://www.sci-corp.com/privacy-policy" class="page-footer__link">{{
              LocalizationService.localize('PrivacyPolicy')
            }}</a>
          </li>
        </ul>
      </nav>
      <div class="page-footer__content__copyright-text">
        <div class="page-footer__content__copyright-text__copyrights">
          ©{{ new Date().getFullYear() }}&nbsp;SCI Shared Resources, LLC
        </div>
        <div class="page-footer__content__copyright-text__copyrights">
          ©{{ new Date().getFullYear() }}&nbsp;
          <a class="page-footer__link" href="https://www.mem.com" target="_blank">Making Everlasting Memories, LLC</a>
        </div>
        <div class="page-footer__content__copyright-text__copyrights">
          {{ LocalizationService.localize('AllRightsReserved') }}
        </div>
        <div class="page-footer__content__one-trust-link">
          <!-- OneTrust Cookies Settings button start -->
          <a
            id="ot-sdk-btn"
            class="ot-sdk-show-settings"
            v-html="
              StringService.preventWidowedWord(LocalizationService.localize('DoNotSellOrShareMyPersonalInformation'))
            "
          ></a>
          <!-- OneTrust Cookies Settings button end -->
        </div>
        <div
          class="page-footer__content__copyright-text__disclaimer"
          v-html="StringService.preventWidowedWord(LocalizationService.localize('SCIDisclaimer'))"
        ></div>
      </div>
    </section>
  </footer>
</template>

<script>
import { LocalizationService } from '@/services/LocalizationService';
import { StringService } from '@/services/StringService';

export default {
  name: 'PageFooter',
  data() {
    return {
      LocalizationService,
      StringService,
    };
  },
  computed: {
    currentLocale() {
      return this.LocalizationService.getLocale();
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/assets/scss/tools' as t;
@use '@/assets/scss/variables' as v;

.page-footer {
  text-align: center;
  font-size: v.$font-size-xs;
  font-family: v.$font-stack-primary;
  margin-top: 6rem;

  &__link {
    text-decoration: none;
  }

  &__content {
    background-color: t.get-system-color('silver', 2);

    &__logo {
      max-width: 75%;
      padding: t.space('xl') t.space('xl') 0 t.space('xl');

      @include t.media-query(min-width, v.$bp-sm) {
        max-width: 40rem;
      }

      @include t.media-query(min-width, v.$bp-xl) {
        margin-top: t.space('xxl');
        margin-bottom: t.space('xl');
      }
    }

    &__copyright-text {
      color: t.get-system-color('silver', 2);
      font-size: v.$font-size-xs;
      background-color: #605f5e; // this color was in the mock up but not in colors.scss
      font-weight: 400;
      padding: 1.5rem;

      &__copyrights {
        text-transform: uppercase;
        margin-bottom: t.space('sm');

        @include t.media-query(min-width, v.$bp-xl) {
          display: inline-flex;
        }
      }

      @include t.media-query(min-width, v.$bp-xl) {
        &__copyrights + &__copyrights::before {
          // \00a0 = a space character
          content: '\00a0|\00a0';
        }
      }

      &__disclaimer {
        margin-top: t.space('xl');
        margin-bottom: t.space('xxl');
        color: t.get-system-color('silver', 1);
        line-height: 1.5;
        font-weight: 400;

        @include t.media-query(min-width, v.$bp-xl) {
          max-width: 70%;
          margin-top: t.space('lg');
          margin-left: auto;
          margin-right: auto;
        }
      }

      .page-footer__link {
        color: t.get-system-color('silver', 1);
      }
    }

    &__one-trust-link {
      margin-top: t.space('lg');
    }

    &__navigation {
      padding: 1.5rem;
      text-transform: uppercase;

      li {
        display: flex;
        align-items: center;
        justify-content: center;

        .page-footer__link {
          color: t.get-system-color('chocolate');
          font-weight: 500;
          font-size: 1rem;
          letter-spacing: 0.1rem;

          @include t.media-query(min-width, v.$bp-md) {
            font-size: 1.2rem;
          }
        }

        .page-footer__link:hover {
          font-weight: 700;
        }
      }
    }
  }

  .horizontal-list {
    button {
      @include t.unbuttonize;
    }

    @include t.media-query(max-width, 500px) {
      flex-direction: column;

      .pipe {
        margin-bottom: 0.4rem;

        &::after {
          display: none;
          margin: 0;
        }
      }
    }
  }
}

#ot-sdk-btn.ot-sdk-show-settings {
  font-family: v.$font-stack-primary;
  color: t.get-system-color('silver', 2);
}
</style>
