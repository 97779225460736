<template>
  <nav class="page-header">
    <BannerFreeShipping />
    <div class="max-width page-header__wrapper">
      <router-link to="/home" @click="trackLogoClick()" class="page-header__logo">
        <img
          v-if="currentLocale.value === 'fr'"
          src="../assets/images/icons/dignity_memorial_logo_FR_chocolate.svg"
          :alt="LocalizationService.localize('DignityMemorial')"
          class="page-header__logo-picture"
        />
        <img
          v-else
          src="../assets/images/icons/dignity_memorial_logo_ENG_chocolate.svg"
          :alt="LocalizationService.localize('DignityMemorial')"
          class="page-header__logo-picture"
        />
      </router-link>
      <button @click="toggleMenu" class="page-header__nav-toggle" data-test="nav-toggle">
        <div class="page-header__nav-toggle-icon-container" :class="{ 'is-nav-open': isFlyoutActive }">
          <span class="page-header__nav-toggle-icon-container__nav-toggle-icon"></span>
        </div>
        <div class="page-header__nav-toggle-label">
          {{ isFlyoutActive ? LocalizationService.localize('Close') : LocalizationService.localize('Menu') }}
        </div>
      </button>
      <div
        class="page-header__flyout-wrapper"
        :class="{
          'display-block': displayMenu,
          'display-none': !displayMenu && !isLargeScreenSize,
        }"
        data-test="flyout-wrapper"
      >
        <div class="page-header__nav-flyout" :class="{ 'is-nav-open': isFlyoutActive }" data-test="nav-flyout">
          <nav class="page-header__nav-flyout__main-nav" :aria-label="LocalizationService.localize('MainNavigation')">
            <ul class="header-nav">
              <li class="header-nav__item hide-on-desktop">
                <router-link
                  to="/home"
                  class="header-nav__link header-nav__link--border-bottom"
                  active-class="header-nav__link--active-route"
                  >{{ LocalizationService.localize('Home') }}</router-link
                >
              </li>
              <li class="header-nav__item">
                <router-link
                  to="/story-book"
                  class="header-nav__link header-nav__link--border-bottom header-nav__keepsake-books"
                  active-class="header-nav__link--active-route"
                  >{{ LocalizationService.localize('KeepsakeBooks') }}</router-link
                >
              </li>
              <li class="header-nav__divider">
                <img class="page-footer__content__logo" src="../assets/images/icons/tappered_line_chocolate.svg" />
              </li>
              <li class="header-nav__item show-on-desktop">
                <LanguageSelector />
              </li>
              <li class="header-nav__divider">
                <img class="page-footer__content__logo" src="../assets/images/icons/tappered_line_chocolate.svg" />
              </li>
              <li class="header-nav__item header-nav__item--reduce-padding">
                <StoryIDSearch
                  class="search-bar--in-header"
                  :labelText="LocalizationService.localize('EnterStoryId')"
                  implementation="header"
                />
              </li>
              <li class="header-nav__item header-nav__item__cart-icon">
                <router-link
                  to="/shopping-cart"
                  class="header-nav__link header-nav__link--without-padding header-nav__link--with-cart-icon"
                  active-class="header-nav__link--active-route"
                  @click="trackCartIconClick()"
                >
                  <ShoppingCartIcon />
                </router-link>
              </li>
            </ul>
          </nav>
          <nav
            class="page-header__nav-flyout__secondary-nav"
            :aria-label="LocalizationService.localize('SecondaryNavigation')"
          >
            <div class="header-nav__call-us hide-on-desktop">
              <p class="header-nav__call-us__text">{{ LocalizationService.localize('CallUsQuestions') }}</p>
              <a
                class="header-nav__call-us__phone-number"
                href="tel:18885494636"
                :aria-label="`${LocalizationService.localize('TalkToARealPerson')} ${LocalizationService.localize(
                  'WeAreHere'
                )} 1 8 8 8. 5 4 9. 4 6 3 6.`"
              >
                1-888-549-4636</a
              >
            </div>
            <ul class="header-nav header-nav--secondary hide-on-desktop">
              <li><LanguageSelector /></li>
            </ul>
          </nav>
        </div>
        <div
          :class="{ 'is-nav-open': isFlyoutActive, 'display-block': displayMenu, 'display-none': !displayMenu }"
          class="page-header__nav-overlay"
          @click="toggleMenu"
          data-test="nav-overlay"
        ></div>
      </div>
    </div>
  </nav>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector.vue';
import StoryIDSearch from '@/components/StoryIDSearch.vue';
import { AnalyticsService } from '@/services/AnalyticsService';
import { LocalizationService } from '@/services/LocalizationService';
import BannerFreeShipping from './BannerFreeShipping.vue';
import ShoppingCartIcon from './ShoppingCartIcon.vue';

export default {
  name: 'PageHeader',
  components: {
    LanguageSelector,
    StoryIDSearch,
    ShoppingCartIcon,
    BannerFreeShipping,
  },
  data() {
    return {
      isFlyoutActive: false,
      displayMenu: false,
      isLargeScreenSize: window.innerWidth >= 1200,
      LocalizationService,
    };
  },
  methods: {
    toggleMenu() {
      if (!this.displayMenu) {
        this.displayMenu = !this.displayMenu;
        setTimeout(() => {
          this.isFlyoutActive = !this.isFlyoutActive;
        }, 1);
      } else {
        this.isFlyoutActive = !this.isFlyoutActive;
        setTimeout(() => {
          this.displayMenu = !this.displayMenu;
        }, 501); // Transition duration plus 1ms TODO: refactor to share with CSS transition
      }
    },
    checkNavForFocus(event) {
      const tabbableElements = [
        ...document.querySelectorAll('.page-header a, .page-header button, .page-header input, .page-header summary'),
      ];

      if (event.key === 'Tab') {
        const hasFocus =
          tabbableElements.filter((item) => {
            return item === document.activeElement;
          }).length > 0;

        if (!hasFocus) {
          this.toggleMenu();
        }
      }
    },
    trackLogoClick() {
      AnalyticsService.analyticsEvent('logo_clicked', 'engagement');
    },
    trackCartIconClick() {
      AnalyticsService.analyticsEvent('shopping_cart_clicked', 'engagement');
    },
  },
  watch: {
    isFlyoutActive(newVal) {
      if (newVal) {
        document.querySelector('body').classList.add('overflow-hidden');
        document.addEventListener('keyup', this.checkNavForFocus);
      } else {
        document.querySelector('body').classList.remove('overflow-hidden');
        document.removeEventListener('keyup', this.checkNavForFocus);
      }
    },
    $route() {
      if (this.displayMenu) {
        this.toggleMenu();
      }
    },
  },
  computed: {
    currentLocale() {
      return this.LocalizationService.getLocale();
    },
  },
  created() {
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.isLargeScreenSize = window.innerWidth >= 1200;
        if (this.displayMenu && this.isLargeScreenSize) {
          this.toggleMenu();
        }
      }, 250);
    });
  },
};
</script>

<style lang="scss">
@use '@/assets/scss/tools' as t;
@use '@/assets/scss/variables' as v;

.header-nav__link--with-cart-icon {
  svg {
    fill: t.get-system-color('turquoise');

    @include t.media-query(min-width, v.$bp-xl) {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  &:focus svg,
  &:hover svg {
    fill: t.get-system-color('turquoise', -1);
  }

  &.header-nav__link--active-route svg {
    @include t.media-query(max-width, v.$bp-xl) {
      fill: t.get-system-color('turquoise');
    }
  }
}
</style>

<style lang="scss" scoped>
@use '@/assets/scss/tools' as t;
@use '@/assets/scss/variables' as v;

$icon-bar-thickness: 0.4rem;
$header-height: 8.3rem; // TODO: Stick this in a variable file

@mixin hamburger-icon-bar {
  display: block;
  height: $icon-bar-thickness;
  width: 100%;
  border-radius: calc($icon-bar-thickness / 4);
  background-color: t.get-system-color('turquoise');
  transition: 0.4s;
}

.page-header {
  position: fixed;
  top: 0;
  background-color: t.get-system-color('turquoise', 2);
  background-size: 40rem;
  color: t.get-system-color('chocolate');
  z-index: t.zindex('navigation-top-z');
  width: 100%;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.75);
  @include t.nav-flyout-text;

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding-top: var(--header-padding);
    height: var(--header-height);
    letter-spacing: 3px;

    @include t.media-query(min-width, v.$bp-xl) {
      justify-content: flex-start;
      height: var(--header-height);
      align-items: center;
      font-size: v.$font-size-sm;
    }
  }

  &__nav-toggle {
    @include t.unbuttonize;
    position: absolute;
    top: var(--header-padding);
    left: var(--header-padding);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include t.media-query(min-width, v.$bp-xl) {
      display: none;
    }
  }

  &__nav-toggle-icon-container {
    height: 4rem;
    width: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.55rem;

    &::before,
    &::after {
      content: '';
      @include hamburger-icon-bar;
    }

    &.is-nav-open::before {
      transform: translate(0%, 230%) rotate(-45deg);
    }

    &.is-nav-open::after {
      transform: translate(0%, -230%) rotate(45deg);
    }

    &__nav-toggle-icon {
      @include hamburger-icon-bar;

      .is-nav-open & {
        opacity: 0;
        margin-left: calc(var(--header-padding) * -1);
      }
    }
  }

  &__nav-toggle-label {
    margin-top: 0.2rem;
    text-align: center;
    text-transform: uppercase;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 100%;
    max-width: 30rem;
    padding-bottom: var(--header-padding);
    text-align: center;

    @include t.media-query(min-width, v.$bp-xl) {
      max-width: 13rem;
      max-height: 4rem;
    }
  }

  &__logo-picture {
    width: 13rem;
  }

  &__nav-flyout {
    @include t.media-query(max-width, v.$bp-xl) {
      position: absolute;
      left: -44.7rem;
      min-height: calc(100vh - $header-height);
      width: 80%;
      max-width: 44.7rem;
      background-color: t.get-system-color('turquoise', 2);
      z-index: t.zindex('navigation-middle-z');
      transition: left 0.5s;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include t.media-query(min-width, v.$bp-xl) {
      background-color: transparent;
      position: initial;
      display: block !important; // HACK: Don't do this in prod
    }

    &.is-nav-open {
      left: 0;
    }

    &__main-nav {
      padding: 10vh 3.7rem;
      color: t.get-system-color('chocolate');

      @include t.media-query(min-width, v.$bp-xl) {
        padding: 0;
      }
    }

    &__secondary-nav {
      background-color: #605f5e;
      padding: 6vh 3.7rem;
      color: t.color('silver', 1);

      @include t.media-query(min-width, v.$bp-xl) {
        background-color: transparent;
        padding: 0;
      }
    }
  }

  &__flyout-wrapper {
    @include t.media-query(max-width, v.$bp-xl) {
      position: fixed;
      top: calc(var(--header-height) + var(--shipping-banner-height));
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
    }

    @include t.media-query(min-width, v.$bp-xl) {
      flex-grow: 2;
    }
  }

  &__nav-overlay {
    position: fixed;
    top: calc(var(--header-height) + var(--shipping-banner-height));
    right: -20%;
    min-height: calc(100vh - $header-height);
    max-width: 20%;
    width: 100vw;
    background-color: rgba(t.get-system-color('chocolate'), 0.8);
    z-index: t.zindex('navigation-bottom-z');
    transition: right 0.5s;

    @media (min-width: 559px) {
      max-width: calc(100vw - 44.7rem);
      right: calc(-1 * (100vw - 44.7rem));
    }

    @include t.media-query(min-width, v.$bp-xl) {
      display: none;
    }

    &.is-nav-open {
      right: 0;
    }
  }
}

.header-nav {
  @include t.delist;

  display: flex;
  flex-direction: column;
  text-align: left;
  text-transform: uppercase;

  @include t.media-query(min-width, v.$bp-xl) {
    flex-direction: row;
    align-items: center;
    justify-content: end;
  }

  &--secondary {
    font-size: v.$font-size-xs;
  }

  &__item {
    @include t.media-query(min-width, v.$bp-xl) {
      padding: t.space('md') 3.5rem;
    }

    &--reduce-padding {
      @include t.media-query(min-width, v.$bp-xl) {
        padding-right: 0;
      }
    }

    &__cart-icon {
      @include t.media-query(min-width, v.$bp-xl) {
        padding-left: 2.5rem;
      }
    }
  }

  &__divider {
    $divider-height: 5rem;
    height: $divider-height;
    width: $divider-height;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 calc(($divider-height / 2) * -1);
    pointer-events: none;

    @include t.media-query(max-width, v.$bp-xl) {
      display: none;
    }

    img {
      transform: rotate(90deg);
      width: 100%;
      height: $divider-height;
    }
  }

  &__link {
    @include t.delink;
    display: block;
    padding: 1rem 0;
    transition: padding 0.2s;
    color: t.get-system-color('chocolate');

    @include t.media-query(max-width, v.$bp-xl) {
      font-size: 1.8rem;
    }

    &:focus,
    &:hover {
      color: t.get-system-color('turquoise');
    }

    &:focus {
      @include t.media-query(max-width, v.$bp-xl) {
        outline: none;
      }
    }

    &--active-route {
      @include t.media-query(max-width, v.$bp-xl) {
        color: t.get-system-color('turquoise');
        font-size: 2rem;
        font-weight: 500;
        outline: none;
      }
    }

    &--border-bottom {
      @include t.media-query(max-width, v.$bp-xl) {
        border-bottom: 0.1rem solid t.get-system-color('silver', 1);
        padding-bottom: 2rem;
        margin-bottom: 1rem;
      }
    }

    &--without-padding {
      @include t.media-query(min-width, v.$bp-xl) {
        padding: 0;
      }
    }

    &--with-cart-icon {
      display: flex;
      align-items: center;

      @include t.media-query(min-width, v.$bp-xl) {
        position: unset;
        display: block;
        padding-top: 0.5rem;
        margin-left: -1rem;
        width: 3.5rem;
        height: 4rem;
        overflow: hidden;
      }
    }
  }

  &__call-us {
    padding-bottom: t.space('xl');
    border-bottom: 0.1rem solid t.get-system-color('silver', 1);
    margin-bottom: t.space('xl');
    font-family: v.$font-stack-secondary;
    font-size: v.$font-size-md;
    text-transform: none;

    &__phone-number {
      color: t.color('turquoise');
      font-family: v.$font-stack-primary;
      text-decoration: none;
      font-size: v.$font-size-sm;
    }
  }
}
</style>
