<template>
  <div class="loader-container">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
};
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/tools' as t;

$outline-opacity: 0.2;

.loader-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsla(0, 100%, 100%, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 99999;

  .loader {
    border-radius: 50%;
    background-color: transparent;
    animation: spin 1s ease-in-out infinite;
    border: 2rem solid t.get-system-color('turquoise', 1);
    border-top: 2rem solid t.get-system-color('turquoise', -1);
    width: 10rem;
    height: 10rem;
    box-shadow: 0 0 6px 0 rgb(133 133 133 / 75%), 0 0 6px 0 rgb(133 133 133 / 75%) inset;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
