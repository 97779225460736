import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import { i18n, updateI18nLocaleByBrowserLanguage } from '@/localization';
import { CONSTANTS } from '@/localization/Constants';
import { AnalyticsService } from '@/services/AnalyticsService';
import { BrowserStorageService } from '@/services/BrowserStorageService';
import { LocalizationService } from '@/services/LocalizationService';
import { RoutingService } from '@/services/RoutingService';
import store, { STORE_NAMESPACES } from '@/store';

const { EN, FR, ES } = CONSTANTS.SUPPORTED_LANG_CODES;

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'redirect',
    redirect: { name: 'home' },
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      titleKey: 'Home',
    },
  },
  // {
  //   path: '/contact-us',
  //   name: 'contact-us',
  //   meta: {
  //     titleKey: 'ContactUs',
  //     breadcrumbTrail: ['home'],
  //   },
  //   component: () => import(/* webpackChunkName: "contact-us" */ '../views/ContactUs.vue'),
  // },
  // {
  //   path: '/contact-us-success',
  //   name: 'contact-us-success',
  //   meta: {
  //     titleKey: 'Success',
  //   },
  //   component: () => import(/* webpackChunkName: "contact-us-success" */ '../views/ContactUsSuccess.vue'),
  // },
  {
    path: '/shopping-cart',
    name: 'shopping-cart',
    meta: {
      titleKey: 'ShoppingCart',
      breadcrumbTrail: [],
    },
    component: () => import(/* webpackChunkName: "shopping-cart" */ '../views/ShoppingCart.vue'),
    beforeEnter: (to, from) => {
      /* Since shopping cart can have two different breadcrumb trails we need to 
      change it depending on where the user came from. */
      if (from.meta.breadcrumbTrail?.includes('SelectStoryBook')) {
        /* To prevent duplicate entries in the breadcrumb trail when the user reaches 
        this route via the Back or Forward browser functions we take the intended breadcrumb 
        trail and slice the array before the 'shopping-cart' entry */
        const fromBreadcrumbs = from.meta.breadcrumbTrail;
        let cleanTrail = fromBreadcrumbs.includes('shopping-cart')
          ? fromBreadcrumbs.slice(0, fromBreadcrumbs.indexOf('shopping-cart'))
          : [...fromBreadcrumbs, from.name];
        to.meta.breadcrumbTrail = cleanTrail;
      } else {
        to.meta.breadcrumbTrail = ['home'];
      }
    },
  },
  {
    path: '/order-success',
    name: 'order-success',
    meta: {
      titleKey: 'Success',
    },
    component: () => import(/* webpackChunkName: "order-success" */ '../views/OrderSuccess.vue'),
  },
  {
    path: '/place-order',
    name: 'place-order',
    meta: {
      titleKey: 'PlaceOrder',
    },
    component: () => import(/* webpackChunkName: "place-order" */ '../views/PlaceOrder.vue'),
  },
  {
    path: '/shipping-info/:uniqueCartItemId',
    name: 'edit-shipping-info',
    meta: {
      titleKey: 'ShippingAddress',
      breadcrumbTrail: ['home', 'SelectStoryBook'],
    },
    component: () => import(/* webpackChunkName: "shipping-info" */ '../views/ShippingInformation.vue'),
  },
  {
    path: '/shipping-info',
    name: 'shipping-info',
    meta: {
      titleKey: 'ShippingAddress',
      breadcrumbTrail: ['home', 'SelectStoryBook'],
    },
    component: () => import(/* webpackChunkName: "shipping-info" */ '../views/ShippingInformation.vue'),
  },
  {
    path: '/story-not-found',
    name: 'story-not-found',
    component: () => import(/* webpackChunkName: "story-not-found" */ '../views/StoryNotFound.vue'),
  },
  {
    path: '/review-order',
    name: 'review-order',
    meta: {
      titleKey: 'ReviewOrder',
      breadcrumbTrail: [],
    },
    component: () => import(/* webpackChunkName: "review-order" */ '../views/ReviewOrder.vue'),
    beforeEnter: (to, from) => {
      if (from.meta.breadcrumbTrail && from.name) {
        to.meta.breadcrumbTrail = [...from.meta.breadcrumbTrail, from.name];
      } else {
        to.meta.breadcrumbTrail = ['home', 'shopping-cart'];
      }
    },
  },
  {
    path: '/story-book',
    name: 'StoryBook',
    meta: {
      titleKey: 'KeepsakeBooks',
      breadcrumbTrail: ['home'],
    },
    component: () => import(/* webpackChunkName: "storyBook" */ '../views/StoryBookHome.vue'),
  },
  {
    path: '/story-book/:theme',
    name: 'StoryBookTheme',
    meta: {
      titleKey: 'KeepsakeBooksStyle',
      breadcrumbTrail: ['home', 'StoryBook'],
    },
    component: () => import(/* webpackChunkName: "storyBook-theme" */ '../views/StoryBookTheme.vue'),
    beforeEnter: (to) => {
      to.meta.title = to.params.theme;
      // For this to work properly, the helper service must return "true" or "undefined" when the route is valid
      // "Undefined" (or "true") will allow this guard to no-op and continue to the original route
      return RoutingService.getStoryBookThemeRoute(to.params.theme);
    },
  },
  {
    path: '/select-story-book/:storyId',
    name: 'SelectStoryBook',
    meta: {
      titleKey: 'SelectStoryBookTitle',
      breadcrumbTrail: ['home'],
    },
    component: () => import(/* webpackChunkName: "select-story-book" */ '../views/SelectStoryBook.vue'),
    beforeEnter: async (to) => {
      try {
        if (to.query.campaign_id) {
          //If we would like to add additional campaign values to track other sources
          const inAnHour = 1 / 24;
          switch (to.query.campaign_id) {
            case 'connect':
              BrowserStorageService.writeCookie('campaign', 'connect', { expires: inAnHour });
              break;
            default:
              break;
          }
        }

        await store.dispatch(`${STORE_NAMESPACES.STORY}/fetchStoryData`, to.params.storyId);

        if (to.query.language) {
          const currentLang = i18n.global.locale.value;

          let newLocale, newLang;
          switch (to.query.language) {
            case EN:
              newLocale = CONSTANTS.ENGLISH;
              newLang = EN;
              break;
            case ES:
              newLocale = CONSTANTS.SPANISH;
              newLang = ES;
              break;
            case FR:
              newLocale = CONSTANTS.FRENCH;
              newLang = FR;
              break;
            default:
              newLocale = CONSTANTS.ENGLISH;
              newLang = EN;
              break;
          }

          AnalyticsService.analyticsEvent(
            'change_site_language',
            'localization',
            undefined,
            `From ${currentLang} to ${newLocale}`
          );
          BrowserStorageService.writeCookie('lang', newLang);
          updateI18nLocaleByBrowserLanguage();
          LocalizationService.setLanguageAttribute();
        }
      } catch ({ message }) {
        if (message === '404') {
          return '/story-not-found';
        } else {
          return '/error';
        }
      }
    },
  },
  {
    path: '/error',
    name: 'Error',
    meta: {
      titleKey: 'SomethingWentWrong',
    },
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
  },
  {
    path: '/confirm-story-change/:newStoryId',
    name: 'ConfirmStoryChange',
    meta: {
      titleKey: 'ConfirmStoryChange',
    },
    component: () => import(/* webpackChunkName: "confirm-story-change" */ '../views/ConfirmStoryChange.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name !== from.name) {
      const app = document.getElementById('app');

      // this check is for the unit tests
      if (app) {
        app.scrollIntoView({ behavior: 'smooth' });
      }

      if (to.name === 'StoryBook') {
        return savedPosition;
      }
    }
  },
});

router.beforeEach(async () => {
  const themes = store.getters[`${STORE_NAMESPACES.APPLICATION}/getAllThemes`];

  if (!themes) {
    try {
      await store.dispatch(`${STORE_NAMESPACES.APPLICATION}/fetchThemeData`);
    } catch {
      router.push('/error');
    }
  }

  return true;
});

export { routes };

export default router;
