export default {
  getAllThemes(state) {
    return state.storyBookThemes;
  },
  getAllThemesAlphabetizedByNameKey(state) {
    return state.storyBookThemes.sort((a, b) => a.nameKey.localeCompare(b.nameKey));
  },
  getThemeById: (state) => (themeId) => {
    return state.storyBookThemes.find((theme) => theme.id === themeId);
  },
  getThemeByName: (state) => (themeName) => {
    return state.storyBookThemes.find((theme) => theme.nameKey.toLowerCase() === themeName.toLowerCase());
  },
  getIsThemeAvailable: (state) => (themeName) => {
    return state.storyBookThemes.some((theme) => theme.nameKey.toLowerCase() === themeName.toLowerCase());
  },
  getIsDataLoaded({ isDataLoaded }) {
    return isDataLoaded;
  },
};
