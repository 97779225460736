import store, { STORE_NAMESPACES } from '@/store';
import { HARD_COVER_VALUE } from './Constants';

const ASSETS_URL = process.env.VUE_APP_CLOUDFRONT_URL;
const DIRECTORY = 'keepsakes/ecommerce';

export class ImageService {
  static getStoryBookHomeSlideShowImages(lang) {
    const themes = store.getters[`${STORE_NAMESPACES.APPLICATION}/getAllThemesAlphabetizedByNameKey`];
    const localDirectory = 'themes/slideshow';
    const filePrefix = `books_slider_`;

    const imagesArray = [];
    themes.forEach((theme) => {
      const newImage = {
        src: `//${ASSETS_URL}/${DIRECTORY}/${localDirectory}/${filePrefix}${theme.nameKey.toLowerCase()}_${lang}.jpg`,
      };
      imagesArray.push(newImage);
    });

    return imagesArray;
  }

  static getStoryBookThemeSlideshowImages(theme) {
    let slideShowImages = [];
    const nameKey = theme.toLowerCase();
    const filePrefix = `style_slider_`;
    const numberOfImages = 5;

    for (let step = 0; step < numberOfImages; step++) {
      const fileNumber = step + 1;

      const obj = {
        src: `//${ASSETS_URL}/${DIRECTORY}/themes/${nameKey}/booksample/${filePrefix}${nameKey}_0${fileNumber}.jpg`,
      };

      slideShowImages.push(obj);
    }

    return slideShowImages;
  }

  static getSelectStoryBookSlideshowImages(theme, coverType) {
    let themeImages = this.getStoryBookThemeSlideshowImages(theme);

    let coverImages = [];
    if (coverType === HARD_COVER_VALUE) {
      coverImages = this.getHardCoverBookImages(2);
    } else {
      coverImages = this.getSoftCoverBookImages(2);
    }

    return [...themeImages, ...coverImages];
  }

  static getHardCoverBookImages(imageAmount = null) {
    let hardCoverImages = [];
    const filePrefix = `books_hardcover_`;
    const numberOfImages = 4;

    for (let step = 0; step < numberOfImages; step++) {
      const fileNumber = step + 1;

      const obj = {
        src: `//${ASSETS_URL}/${DIRECTORY}/bookcovers/${filePrefix}0${fileNumber}.jpg`,
      };

      hardCoverImages.push(obj);
    }

    if (imageAmount) {
      return hardCoverImages.slice(0, imageAmount);
    }
    return hardCoverImages;
  }

  static getSoftCoverBookImages(imageAmount = null) {
    let softCoverImages = [];
    const filePrefix = `books_softcover_`;
    const numberOfImages = 4;

    for (let step = 0; step < numberOfImages; step++) {
      const fileNumber = step + 1;

      const obj = {
        src: `//${ASSETS_URL}/${DIRECTORY}/bookcovers/${filePrefix}0${fileNumber}.jpg`,
      };

      softCoverImages.push(obj);
    }

    if (imageAmount) {
      return softCoverImages.slice(0, imageAmount);
    }
    return softCoverImages;
  }

  static getStyleLinkButtonImages(nameKey, mobileImage = false) {
    const localDirectory = 'themes/graphicstylebuttons';
    const format = mobileImage ? 'mobile' : 'desktop';
    let filePrefix = 'books_graphicstyle_button_';
    filePrefix = mobileImage ? `${filePrefix}${nameKey.toLowerCase()}_m` : `${filePrefix}${nameKey.toLowerCase()}`;

    return `//${ASSETS_URL}/${DIRECTORY}/${localDirectory}/${format}/${filePrefix}.jpg`;
  }

  static getStoryBookSampleImages(theme) {
    let sampleImages = [];
    const nameKey = theme.toLowerCase();
    const filePrefix = `style_sampleimagery_`;
    const numberOfImages = 7;

    for (let step = 0; step < numberOfImages; step++) {
      const fileNumber = step + 1;

      const obj = {
        src: `//${ASSETS_URL}/${DIRECTORY}/themes/${nameKey}/sampleimages/${filePrefix}${nameKey}_0${fileNumber}.jpg`,
      };

      sampleImages.push(obj);
    }

    return sampleImages;
  }

  static getHomePageIntroImage() {
    const localDirectory = 'home';
    const fileName = 'home_image_01';

    return `//${ASSETS_URL}/${DIRECTORY}/${localDirectory}/${fileName}.jpg`;
  }

  static getEveryDetailInEveryDotImage(mobileImage = true) {
    const localDirectory = 'everydetailineverydot';
    const fileName = mobileImage ? 'books_image_01' : 'books_image_02';

    return `//${ASSETS_URL}/${DIRECTORY}/${localDirectory}/${fileName}.jpg`;
  }
}
