<template>
  <div class="free-shipping-banner">
    <div class="free-shipping-banner__content">
      <img src="../assets/images/icons/awesome-bookmark.svg" alt="awesome-bookmark" class="awesome-bookmark" />
      <span v-html="StringService.preventWidowedWord(LocalizationService.localize('EnjoyFreeShipping'))"></span>
    </div>
  </div>
</template>

<script>
import { LocalizationService } from '@/services/LocalizationService';
import { StringService } from '@/services/StringService';

export default {
  name: 'BannerFreeShipping',
  emits: [],
  props: {},
  data() {
    return {
      LocalizationService,
      StringService,
    };
  },
  computed: {},
};
</script>
<style lang="scss">
@use '../assets/scss/variables' as v;

.free-shipping-banner__content {
  .bold-text {
    font-family: v.$font-stack-tertiary;
    text-transform: uppercase;
    font-size: 2.4rem;
    font-weight: 500;

    @media (max-width: v.$bp-xs-8) {
      font-size: 1.6rem;
    }
  }
}
</style>
<style scoped lang="scss">
@use '../assets/scss/tools' as t;
@use '../assets/scss/variables' as v;

.free-shipping-banner {
  background-color: t.get-system-color('chocolate', 0);
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 0.3px 0.6px t.get-system-color('blueberry', -2);
  height: var(--shipping-banner-height);

  &__content {
    color: t.get-system-color('silver', 2);
    font-family: v.$font-stack-secondary;
    font-size: 3.2rem;
    line-height: 3.6rem;
    letter-spacing: 0;
    text-align: center;
    width: auto;
    text-transform: none;
    padding: 0.7rem 0;

    @include t.media-query(max-width, v.$bp-xs-8) {
      font-size: 2.2rem;
      line-height: 2.4rem;
      padding: 1rem 0;
    }

    .awesome-bookmark {
      margin-top: -1rem;
      margin-bottom: -1.5rem;
      margin-right: 1rem;
      margin-left: 1rem;
      transform: translateY(-1rem);

      @include t.media-query(max-width, v.$bp-xs-8) {
        width: 1.941rem;
        transform: translateY(-1.5rem);
      }
    }
  }
}
</style>
