<template>
  <LoadingIcon v-if="!getIsDataLoaded" />
  <div class="page" :class="{ 'show-background': isHomePage, 'overflow-hidden': !getIsDataLoaded }">
    <PageHeader />
    <div class="page-content">
      <div id="image-carousel--home"></div>
      <!-- Removing Breadcrumb until later phase -->
      <!-- <RouteBreadcrumb class="show-on-desktop" :routes="breadcrumbRoutes" v-if="showBreadcrumbs" /> -->
      <div class="show-on-desktop breadcrumb-placeholder" v-if="showBreadcrumbs"></div>
      <main>
        <router-view />
      </main>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import LoadingIcon from '@/components/LoadingIcon.vue';
import PageFooter from '@/components/PageFooter.vue';
import PageHeader from '@/components/PageHeader.vue';
// import RouteBreadcrumb from '@/components/RouteBreadcrumb.vue';
import { i18n, updateI18nLocaleByBrowserLanguage } from '@/localization';
import { BrowserStorageService } from '@/services/BrowserStorageService';
import { SERVICE_CONSTANTS } from '@/services/Constants';
import { LocalizationService } from '@/services/LocalizationService';
import { STORE_NAMESPACES } from '@/store';
import { RoutingService } from './services/RoutingService';
import { StringService } from './services/StringService';

export default {
  name: 'App',
  components: {
    LoadingIcon,
    PageFooter,
    PageHeader,
    // RouteBreadcrumb,
  },
  data() {
    return {
      LocalizationService,
      isHomePage: false,
      isDataLoaded: false,
      i18n,
    };
  },
  created() {
    // check localStorage for cart items
    const existingCartItems = BrowserStorageService.getLocalStorageItemByKey(
      SERVICE_CONSTANTS.LOCAL_STORAGE_KEYS.SHOPPING_CART
    );

    if (existingCartItems) {
      this.SYNC_CART_ITEMS_WITH_LOCAL_STORAGE(existingCartItems);
    }

    // set lang attribute on <html> element
    LocalizationService.setLanguageAttribute();
  },
  methods: {
    ...mapMutations(STORE_NAMESPACES.SHOPPINGCART, ['SYNC_CART_ITEMS_WITH_LOCAL_STORAGE']),
    updateLanguage(lang) {
      BrowserStorageService.writeCookie('lang', lang);
      updateI18nLocaleByBrowserLanguage();
      LocalizationService.setLanguageAttribute();
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'home') {
        this.isHomePage = true;
      } else {
        this.isHomePage = false;
      }

      let pageTitle = RoutingService.getPageTitle(to);

      document.title = StringService.removeHTML(pageTitle) || 'MeM Story Books';
    },
    getIsDataLoaded(loaded) {
      if (!loaded) {
        document.body.classList.add('loading-icon');
      } else {
        document.body.classList.remove('loading-icon');
      }
    },
    'i18n.global.locale'() {
      let pageTitle = RoutingService.getPageTitle(this.$route);

      document.title = StringService.removeHTML(pageTitle) || 'MeM Story Books';
    },
  },
  computed: {
    ...mapGetters(STORE_NAMESPACES.APPLICATION, ['getIsDataLoaded']),
    ...mapGetters(STORE_NAMESPACES.SHOPPINGCART, ['getShoppingCartItemCount']),
    ...mapGetters(STORE_NAMESPACES.STORY, ['getStoryData']),
    currentRoute() {
      return this.$router.currentRoute.value;
    },
    showBreadcrumbs() {
      const routesToHide = ['home', 'story-not-found', 'contact-us-success', 'order-success'];
      return !routesToHide.includes(this.currentRoute.name);
    },
    breadcrumbRoutes() {
      return RoutingService.getBreadcrumbsByRoute(this.$router, this.currentRoute);
    },
  },
  mounted() {
    if (!this.getIsDataLoaded) {
      document.body.classList.add('loading-icon');
    } else {
      document.body.classList.remove('loading-icon');
    }
  },
};
</script>

<style lang="scss">
@use '@/assets/scss/tools' as t;
@use '@/assets/scss/variables' as v;
@use '@/assets/scss/settings/font-face.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --header-padding: 1.5rem;
  --header-height: 8.3rem; // TODO: Stick this in a variable file
  --image-carousel-height: initial;
  --breadcrumb-height: 5rem;
  --shipping-banner-height: 5rem;

  @include t.media-query(min-width, v.$bp-md) {
    --header-padding: 2rem;
    --header-height: 9rem;
    --shipping-banner-height: 5rem;
  }

  @include t.media-query(min-width, v.$bp-xl) {
    --header-padding: 0.5rem;
  }

  @include t.media-query(max-width, v.$bp-xs-8) {
    --shipping-banner-height: 4.4rem;
  }
}

html {
  font-size: 62.5%;
}

body {
  &.loading-icon {
    overflow: hidden;
  }
}

#app {
  @include t.body;
  font-family: v.$font-stack-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  gap: 4rem;

  .open-gallery-modal & {
    overflow-y: hidden;
    height: 100vh;
  }
}

p {
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.show-background {
  background-size: 70rem;

  @include t.media-query(min-width, v.$bp-lg) {
    background-size: 100rem;
  }

  @include t.media-query(min-width, v.$bp-xxl) {
    background-size: 150rem;
  }
}

.page-content {
  flex-grow: 2;
  padding-top: calc(var(--header-height) + var(--shipping-banner-height));
}

.breadcrumb-placeholder {
  margin-top: var(--breadcrumb-height);
}

.horizontal-list {
  @include t.delist;
  display: flex;
  justify-content: center;
}

.pipe {
  @include t.dividing-pipe;
}

.hideable-break {
  @include t.media-query(min-width, v.$bp-xl) {
    display: none;
  }
}

.hideable-break-tablet {
  @include t.media-query(min-width, v.$bp-xxl) {
    display: none;
  }
}

.show-on-desktop {
  display: none;

  @include t.media-query(min-width, v.$bp-xl) {
    display: block;
  }
}

.hide-on-desktop {
  display: block;

  @include t.media-query(min-width, v.$bp-xl) {
    display: none !important;
  }
}

.max-width {
  width: 100%;
  max-width: 130rem;
  margin-left: auto;
  margin-right: auto;
}

.view-padding {
  padding: 0 3.7rem;

  @include t.media-query(max-width, v.$bp-xs-6) {
    padding: 0 2.9rem;
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

#onetrust-consent-sdk {
  font-family: v.$font-stack-onetrust-body;

  .otPcTab .ot-sdk-row .ot-sdk-column {
    margin-left: 0 !important;
  }

  .ot-pc-footer .ot-btn-container .ot-btn-subcntr {
    max-width: none !important;

    button {
      margin-right: 16px !important;
    }
  }
}
</style>
