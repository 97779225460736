import store, { STORE_NAMESPACES } from '@/store';
import { LocalizationService } from './LocalizationService';
import { StringService } from './StringService';

export class RoutingService {
  static getStoryBookThemeRoute(themeName) {
    if (store.getters[`${STORE_NAMESPACES.APPLICATION}/getIsThemeAvailable`](themeName)) {
      // This needs to return "undefined" when the theme route is already valid
      // If we returned the actual route, we would end up in an infinite loop
      // "Undefined" will allow the guard to no-op and continue to the original route
      return undefined;
    } else {
      return '/story-book';
    }
  }

  static getPageTitle(route) {
    if (!route.meta.titleKey) {
      return '';
    }

    if (route.meta.titleKey === 'SelectStoryBookTitle') {
      const storyName = store.getters[`${STORE_NAMESPACES.STORY}/getStoryData`].name;
      return LocalizationService.localize(route.meta.titleKey, null, { name: storyName });
    }

    return LocalizationService.localize(route.meta.titleKey);
  }

  static getPageTitleForAnalytics(route) {
    if (!route.meta.titleKey) {
      return route.name;
    }

    let englishTitle = LocalizationService.localize(route.meta.titleKey, undefined, undefined, 'en');

    return StringService.removeHTML(englishTitle);
  }

  static getBreadcrumbsByRoute(router, currentRoute) {
    const routes = router.getRoutes();
    const crumbRouteNames = currentRoute.meta.breadcrumbTrail;
    let returnList = [];

    if (crumbRouteNames) {
      crumbRouteNames.forEach((routeName) => {
        const foundRoute = routes.find((route) => route.name === routeName);
        returnList.push({ name: this.getPageTitle(foundRoute), path: foundRoute.path });
      });
    }

    returnList.push({ name: this.getPageTitle(currentRoute), path: currentRoute.path });

    return returnList;
  }
}
