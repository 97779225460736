<template>
  <details class="language-selector" ref="details" @toggle="addToggleEventListener" data-test="details">
    <summary class="language-selector__summary" data-test="summary">{{ selectedLanguage }}</summary>
    <ul class="language-selector__flyout">
      <li v-for="lang in unSelectedLanguages" :key="lang.langCode" @click="updateLanguage(lang.langCode)">
        <button class="language-selector__option" data-test="options">
          {{ lang.langText }}
        </button>
      </li>
    </ul>
  </details>
</template>

<script>
import { i18n, updateI18nLocaleByBrowserLanguage } from '@/localization';
import { CONSTANTS } from '@/localization/Constants';
import { AnalyticsService } from '@/services/AnalyticsService';
import { BrowserStorageService } from '@/services/BrowserStorageService';
import { LocalizationService } from '@/services/LocalizationService';

export default {
  name: 'LanguageSelector',
  data() {
    return {
      i18n,
      LocalizationService,
      currentLang: i18n.global.locale,
    };
  },
  methods: {
    checkLanguageFlyoutForFocus(event) {
      if (event.key === 'Tab') {
        this.matchElementsAgainstSpecificTarget(
          '.language-selector__summary, .language-selector__option',
          document.activeElement
        );
      }
    },
    checkLanguageFlyoutForOutsideClick(event) {
      this.matchElementsAgainstSpecificTarget(
        '.language-selector__summary, .language-selector__flyout, .language-selector__option',
        event.target
      );
    },
    matchElementsAgainstSpecificTarget(selectorForElements, specificTarget) {
      const validElements = [...document.querySelectorAll(selectorForElements)];

      const isActive =
        validElements.filter((item) => {
          return item === specificTarget;
        }).length > 0;

      if (!isActive) {
        this.toggleDetailer();
      }
    },
    addToggleEventListener() {
      if (this.$refs.details.open) {
        window.addEventListener('keyup', this.checkLanguageFlyoutForFocus);
        window.addEventListener('click', this.checkLanguageFlyoutForOutsideClick);
      } else {
        window.removeEventListener('keyup', this.checkLanguageFlyoutForFocus);
        window.removeEventListener('click', this.checkLanguageFlyoutForOutsideClick);
      }
    },
    toggleDetailer() {
      this.$refs.details.open = !this.$refs.details.open;
    },
    updateLanguage(lang) {
      AnalyticsService.analyticsEvent(
        'change_site_language',
        'localization',
        undefined,
        `From ${this.currentLang} to ${lang}`
      );
      BrowserStorageService.writeCookie('lang', lang);
      updateI18nLocaleByBrowserLanguage();
      LocalizationService.setLanguageAttribute();

      this.currentLang = this.i18n.global.locale;

      location.reload();
    },
    convertLangCodeToText(locale) {
      const { EN, FR, ES } = CONSTANTS.SUPPORTED_LANG_CODES;
      let currentLanguage = '';

      switch (locale) {
        case EN:
          currentLanguage = CONSTANTS.ENGLISH;
          break;
        case FR:
          currentLanguage = CONSTANTS.FRENCH;
          break;
        case ES:
          currentLanguage = CONSTANTS.SPANISH;
          break;
        default:
          currentLanguage = CONSTANTS.ENGLISH;
          break;
      }
      return currentLanguage;
    },
  },
  computed: {
    selectedLanguage() {
      return this.convertLangCodeToText(this.currentLang);
    },
    unSelectedLanguages() {
      const { EN, FR, ES } = CONSTANTS.SUPPORTED_LANG_CODES;
      const supportedLanguageCodes = [EN, FR, ES];

      const notSelectedLangs = supportedLanguageCodes.filter((code) => {
        return code !== this.currentLang;
      });

      const mappedNonSelections = notSelectedLangs.map((lang) => {
        const langObj = {};
        langObj.langCode = lang;
        langObj.langText = this.convertLangCodeToText(lang);
        return langObj;
      });
      return mappedNonSelections;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/assets/scss/tools' as t;
@use '@/assets/scss/variables' as v;

.language-selector {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 1.2rem;
  color: inherit;

  &__summary {
    @include t.nav-flyout-text;
    position: relative;
    z-index: t.zindex('navigation-top-z');

    // This is to position everything correctly in browsers that do not support the
    // -webkit-details-marker pseudo element
    display: inline-flex;
    align-items: center;
    padding-right: 2rem;

    // Visually removes the default arrow icon. We do need this element for screen
    // reader accessiblilty so we don't want to set display: none;
    &::-webkit-details-marker,
    &::marker {
      display: block;
      height: 0;
      width: 0;
      overflow: hidden;
    }

    &::after {
      content: '';
      display: inline-block;
      height: 1rem;
      width: 1rem;
      border-right: 3px solid t.get-system-color('turquoise');
      border-bottom: 3px solid t.get-system-color('turquoise');
      transform-origin: 75% 75%;
      transform: rotate(45deg) translate(0, -0.25rem);
      transition: all 0.12s 0.35s ease;
      position: absolute;
      right: 0;

      .language-selector[open] & {
        transform: rotate(225deg) translate(0, 0.7rem);
      }
    }

    &:focus {
      @include t.media-query(max-width, v.$bp-xl) {
        outline: none;

        .language-selector:not([open]) &::after {
          animation: bump-right 0.2s linear;
          border-color: t.get-system-color('turquoise');
        }
      }
    }

    &:hover {
      color: t.get-system-color('turquoise');
    }
  }

  &__option {
    @include t.unbuttonize;
    @include t.nav-flyout-text;
    color: inherit;

    &:focus {
      color: t.get-system-color('turquoise');
      outline: none;
    }
  }

  &__flyout {
    @include t.delist;
    top: 0;
    left: calc(100% - 1.8rem);
    padding: 2rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4rem;

    @include t.media-query(min-width, v.$bp-xl) {
      position: absolute;
      background-color: t.get-system-color('turquoise', 2);
      border: 1px solid t.get-system-color('silver', 1);
      top: 100%;
      left: 0;
      padding: 0;
      margin-top: 1.3rem;
      gap: 0;

      li {
        padding: t.space('lg') t.space('xl');

        &:hover {
          background-color: white;
        }
      }
    }
  }
}

@keyframes bump-right {
  50% {
    margin-left: 1rem;
  }
}
</style>
