import { LoggingService } from '@memllc/component-library';
import axios from 'axios';
import vuexStore, { STORE_NAMESPACES } from '@/store';
import { AnalyticsService } from './AnalyticsService';

export class ApiService {
  static get(url, config) {
    vuexStore.dispatch(`${STORE_NAMESPACES.APPLICATION}/setIsDataLoaded`, false);
    return axios
      .get(url, config)
      .then(({ data: responseData }) => {
        vuexStore.dispatch(`${STORE_NAMESPACES.APPLICATION}/setIsDataLoaded`, true);
        LoggingService.debug(`API GET (${url}) ran successfully`);
        return responseData;
      })
      .catch((error) => {
        // TODO: Handle errors
        vuexStore.dispatch(`${STORE_NAMESPACES.APPLICATION}/setIsDataLoaded`, true);

        // Log more specific error when we are able to determine Internal users
        LoggingService.error(`GET Error: ${error.response.status}`);
        AnalyticsService.analyticsException(`GET API call failed: ${url}`, true);

        throw Error(error.response.status);
      });
  }

  static post(url, data, config) {
    vuexStore.dispatch(`${STORE_NAMESPACES.APPLICATION}/setIsDataLoaded`, false);
    return axios
      .post(url, data, config)
      .then(({ data: responseData }) => {
        vuexStore.dispatch(`${STORE_NAMESPACES.APPLICATION}/setIsDataLoaded`, true);
        LoggingService.debug(`API POST (${url}) ran successfully`);
        return responseData;
      })
      .catch((error) => {
        // TODO: Handle errors
        vuexStore.dispatch(`${STORE_NAMESPACES.APPLICATION}/setIsDataLoaded`, true);

        // Log more specific error when we are able to determine Internal users
        LoggingService.error(`POST Error: ${error.response.status}`);
        AnalyticsService.analyticsException(`POST API call failed: ${url}`, true);

        throw Error(error.response.status);
      });
  }

  static put(url, data, config) {
    vuexStore.dispatch(`${STORE_NAMESPACES.APPLICATION}/setIsDataLoaded`, false);
    return axios
      .put(url, data, config)
      .then(({ data: responseData }) => {
        vuexStore.dispatch(`${STORE_NAMESPACES.APPLICATION}/setIsDataLoaded`, true);
        LoggingService.debug(`API PUT (${url}) ran successfully`);
        return responseData;
      })
      .catch((error) => {
        // TODO: Handle errors
        vuexStore.dispatch(`${STORE_NAMESPACES.APPLICATION}/setIsDataLoaded`, true);

        // Log more specific error when we are able to determine Internal users
        LoggingService.error(`PUT Error: ${error.response.status}`);
        AnalyticsService.analyticsException(`PUT API call failed: ${url}`, true);

        throw Error(error.response.status);
      });
  }
}
