<template>
  <div class="max-width">
    <Teleport to="#image-carousel--home">
      <header aria-label="hero slideshow">
        <div class="carousel-container">
          <ImageCarousel :speed="750" :images="imagesArray" maxHeight="unset" pagination autoplay />
          <div class="carousel-container__banner">
            <span v-html="StringService.preventWidowedWord(LocalizationService.localize('HomePageSlideshow'))"></span>
          </div>
        </div>
      </header>
    </Teleport>
    <div class="introduction view-padding">
      <img class="introduction__book-image" :src="introImageURL" @error="logImageError" />
      <div class="introduction__intro-text">
        <h1>{{ LocalizationService.localize('PersonalizedKeepsakeBooks') }}</h1>
        <p>
          <span v-html="LocalizationService.localize('HomePageKeepsake')"></span>
        </p>
      </div>
      <StoryIDSearch class="introduction__search" implementation="home-page" />
    </div>
    <CallUs />
  </div>
</template>

<script>
import boy_father_floor_mobile from '@/assets/images/boy_father_floor_mobile.jpg';
import boy_father_floor from '@/assets/images/father_boy_floor.jpg';
import stackedphotos_bow from '@/assets/images/stackedphotos_bow.jpg';
import stackedphotos_bow_mobile from '@/assets/images/stackedphotos_bow_mobile.jpg';
import two_smiling_redglasses from '@/assets/images/two_smiling_redglasses.jpg';
import two_smiling_redglasses_mobile from '@/assets/images/two_smiling_redglasses_mobile.jpg';

import CallUs from '@/components/CallUs.vue';
import ImageCarousel from '@/components/ImageCarousel/ImageCarousel.vue';
import StoryIDSearch from '@/components/StoryIDSearch.vue';
import { AnalyticsService } from '@/services/AnalyticsService';
import { ImageService } from '@/services/ImageService';
import { LocalizationService } from '@/services/LocalizationService';
import { StringService } from '@/services/StringService';

export default {
  name: 'Home',
  components: {
    CallUs,
    ImageCarousel,
    StoryIDSearch,
  },
  data() {
    return {
      LocalizationService,
      ImageService,
      StringService,
      isMobileScreenSize: window.innerWidth <= 700,
    };
  },
  computed: {
    introImageURL() {
      return ImageService.getHomePageIntroImage();
    },
    imagesArray() {
      const mobileArray = [
        { src: stackedphotos_bow_mobile, alt: 'Stacked Photos Bow' },
        { src: two_smiling_redglasses_mobile, alt: 'Two Smiling Red Glasses' },
        { src: boy_father_floor_mobile, alt: 'Boy Father Floor' },
      ];

      const tabletDesktopArray = [
        { src: stackedphotos_bow, alt: 'Stacked Photos Bow' },
        { src: two_smiling_redglasses, alt: 'Two Smiling Red Glasses' },
        { src: boy_father_floor, alt: 'Boy Father Floor' },
      ];

      return this.isMobileScreenSize ? mobileArray : tabletDesktopArray;
    },
  },
  methods: {
    logImageError() {
      AnalyticsService.analyticsException('Home - Intro image failed to load', false);
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.isMobileScreenSize = window.innerWidth <= 700;
      }, 250);
    });
  },
};
</script>

<style lang="scss">
@use '@/assets/scss/variables' as v;

.carousel-container {
  &__banner {
    & .alt-font {
      font-family: v.$font-stack-secondary;
    }
  }
}
</style>

<style lang="scss" scoped>
@use '@/assets/scss/tools' as t;
@use '@/assets/scss/variables' as v;

.carousel-container {
  position: relative;

  --image-carousel-height: initial;

  @include t.media-query(min-width, v.$bp-md) {
    --image-carousel-height: 40rem;
  }

  @include t.media-query(min-width, v.$bp-lg) {
    --image-carousel-height: 45rem;
  }

  @include t.media-query(min-width, v.$bp-xxxl) {
    --image-carousel-height: initial;
  }

  &__banner {
    position: absolute;
    font-family: v.$font-stack-tertiary;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: t.space('md') 1rem;
    color: white;
    font-size: 2.8rem;
    bottom: 3.9rem;
    z-index: t.zindex('page-level-top-z');
    width: 100%;
    background-color: rgba(t.get-system-color('teal'), 0.75);
    line-height: 100%;

    @include t.media-query(min-width, v.$bp-sm) {
      font-size: 4rem;
    }

    @include t.media-query(min-width, v.$bp-md) {
      font-size: 4rem;
    }

    @include t.media-query(min-width, v.$bp-xl) {
      font-size: 4rem;
      bottom: 5.4rem;
    }
  }
}

.introduction {
  margin-top: 4rem;
  margin-bottom: 6rem;

  @include t.media-query(min-width, v.$bp-md) {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: t.space('xxl');
    row-gap: t.space('sm');
    justify-content: center;
    grid-template-areas:
      'book-image intro-text'
      'book-image search';
  }

  @include t.media-query(min-width, v.$bp-xl) {
    grid-template-columns: 50rem 40rem;
  }

  &__book-image {
    display: block;
    object-fit: contain;
    max-width: 100%;
    margin: 0 auto;

    @include t.media-query(min-width, v.$bp-md) {
      grid-area: book-image;
      align-self: center;
    }
  }

  &__intro-text {
    font-size: v.$font-size-sm;
    font-family: v.$font-stack-primary;
    color: t.get-system-color('chocolate');
    margin-bottom: t.space('lg');

    @include t.media-query(min-width, v.$bp-md) {
      grid-area: intro-text;
      align-self: end;
      margin-bottom: 0;
      margin-top: t.space('lg');
    }

    h1 {
      font-size: v.$font-size-xl;
      font-family: v.$font-stack-secondary;
      font-weight: 200;
      margin-top: t.space('lg');
      margin-bottom: t.space('lg');
      padding-right: 8rem;

      @include t.media-query(min-width, v.$bp-xs) {
        padding-right: 10rem;
      }

      @include t.media-query(min-width, v.$bp-xs-5-2) {
        padding-right: 14rem;
      }

      @include t.media-query(min-width, v.$bp-xs-6-2) {
        padding-right: unset;
      }

      @include t.media-query(min-width, v.$bp-md) {
        padding-right: 8rem;
        margin-top: 0;
        margin-bottom: t.space('sm');
      }

      @include t.media-query(min-width, v.$bp-md-3) {
        padding-right: unset;
      }
    }

    p {
      font-size: v.$font-size-sm;

      .italic {
        font-style: italic;
      }
    }
  }

  &__search {
    grid-area: search;

    @include t.media-query(min-width, v.$bp-md) {
      max-width: 300px;
    }
  }
}
</style>
