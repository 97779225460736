import { createI18n } from 'vue-i18n';
import en from '@/localization/en.json';
import es from '@/localization/es.json';
import fr from '@/localization/fr.json';
import { BrowserStorageService } from '@/services/BrowserStorageService';

const applicationSupportedLanguage = {
  ENGLISH: 'en',
  SPANISH: 'es',
  FRENCH: 'fr',
};

const languageDefault = applicationSupportedLanguage.ENGLISH;

const convertLangCodeStringToEnum = (langCode) => {
  switch (langCode) {
    case 'en':
      return applicationSupportedLanguage.ENGLISH;
    case 'es':
      return applicationSupportedLanguage.SPANISH;
    case 'fr':
      return applicationSupportedLanguage.FRENCH;
  }
  return undefined;
};

const languageIsSupported = (language) => {
  const supportedLanguage = convertLangCodeStringToEnum(language);
  if (supportedLanguage) {
    return true;
  }
  return false;
};

const getLanguageFromLanguageCode = (languageCode) => languageCode.substring(0, 2);

const getSupportedLanguageFromMultipleLanguageCodes = (languagesCodes) => {
  const languages = languagesCodes.map(getLanguageFromLanguageCode);
  const languagePreferred = languages.find(languageIsSupported);

  return convertLangCodeStringToEnum(languagePreferred) ?? languageDefault;
};

const getBrowserLocale = () => {
  const langCookie = BrowserStorageService.getCookieValueByCookieName('lang');
  if (langCookie) return [langCookie];

  if (window.navigator?.languages) {
    return navigator.languages.concat();
  } else if (window.navigator?.language) {
    return [window.navigator.language];
  }
  return [];
};

export const determineBrowserPreferredLanguage = () => {
  const browserLocales = getBrowserLocale();
  return getSupportedLanguageFromMultipleLanguageCodes(browserLocales);
};

export const getRouteLanguage = () => {
  const url = window.location.toString();
  let lang = null;

  if (url.includes('locale:en')) {
    lang = 'en';
  } else if (url.includes('locale:fr')) {
    lang = 'fr';
  } else if (url.includes('locale:es')) {
    lang = 'es';
  }

  return lang;
};

const determinedLanguage = getRouteLanguage() ? getRouteLanguage()?.toString() : determineBrowserPreferredLanguage();

export const i18n = createI18n({
  legacy: false,
  locale: determinedLanguage,
  fallbackLocale: 'en',
  messages: {
    en,
    es,
    fr,
  },
  datetimeFormats: {
    en: {
      full: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
      short: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      numeric: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
      year: {
        year: 'numeric',
      },
    },
    fr: {
      full: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
      short: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      numeric: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
      year: {
        year: 'numeric',
      },
    },
    es: {
      full: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
      short: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      numeric: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
      year: {
        year: 'numeric',
      },
    },
  },
});

export const updateI18nLocaleByBrowserLanguage = () => {
  let determinedLang = determineBrowserPreferredLanguage();
  i18n.global.locale.value = determinedLang;
};
