import { BrowserStorageService } from '@/services/BrowserStorageService';

export default {
  removeShoppingCartItem({ commit, state }, uniqueId) {
    commit('REMOVE_SHOPPING_CART_ITEM', uniqueId);
    BrowserStorageService.backupStoreCartItemsToLocalStorage(state.shoppingCartItems);
  },
  setQuantityValue({ commit, state }, quantityChangeObj) {
    commit('SET_QUANTITY_VALUE', quantityChangeObj);
    BrowserStorageService.backupStoreCartItemsToLocalStorage(state.shoppingCartItems);
  },
  addItemToShoppingCart({ commit }, cartItem) {
    commit('ADD_ITEM_TO_SHOPPING_CART', cartItem);
    commit('DEQUEUE_BOOK_ORDER');
  },
  editShippingAddressById({ commit }, { uniqueId, newAddress }) {
    commit('EDIT_SHIPPING_ADDRESS_BY_ID', { uniqueId, newAddress });
  },
};
