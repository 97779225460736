export const CONSTANTS = {
  ENGLISH: 'English',
  FRENCH: 'Français',
  SPANISH: 'Español',
  SUPPORTED_LANG_CODES: {
    EN: 'en',
    FR: 'fr',
    ES: 'es',
  },
};

export const FRENCH_VERSES_AND_POEMS = [
  { author: 'Anonyme', body: 'Seulement que peu de choses sont vraiment importantes. ' },
  {
    author: 'Ariane Angeloglou',
    body: 'L’amour est la source de lumière qui apporte à la vie son meilleur éclairage. ',
  },
  { author: 'Pearl Buck', body: 'La vraie sagesse de la vie consiste à voir l’extraor- dinaire dans l’ordinaire. ' },
  { author: 'Beaumarchais', body: 'L’amour n’est que le roman du cœur, c’est le plaisir qui en est l’histoire. ' },
  { author: 'Anonyme', body: 'Les sentiments du cœur sont éternels. ' },
  {
    author: 'Etienne Rioux',
    body: 'Comme une photo<br>que l’on aime revoir,<br>un beau souvenir, <br>un mot ou un sourire<br>qui peut nous émouvoir, <br>ceux qui nous sont chers<br>revivent dans notre <br>mémoire comme si c’était hier. <br>Que de fois un simple<br>coucher de soleil éveille <br>en notre esprit avec<br>une douce nostalgie <br>ces moments privilégiés<br>partagés avec un être aimé ! <br>Oui, la mémoire possède<br>ce pouvoir de faire vivre <br>par la pensée ceux qui<br>nous ont quittés.',
  },
  {
    author: 'Anonyme',
    body: 'Ne soyez pas tristes,<br> oubliez votre chagrin,<br> car là où je suis maintenant,<br> il n’y a ni larmes, ni souffrance,<br>  ni maladie, ni douleur.<br>  Le bonheur que je vis<br> dans les cieux <br> est encore plus grand<br> que celui que j’ai connu sur terre.<br> J’ai trouvé auprès de Dieu <br> la lumière et l’amour infini. <br> Réjouissez-vous, il veille sur moi <br> et m’a accordé le repos <br> et la paix éternelle.',
  },
  {
    author: 'Anonyme',
    body: 'Au bout du chemin<br>de la vie<br>se trouve un seuil qui<br>nous conduit<br>de l’autre côté<br>de la nuit,<br>vers la lumière<br>où nous attendent<br>le repos et la paix…',
  },
  { author: 'Jean Anouilh', body: 'C’est dans le souvenir que les choses prennent leur vraie place.' },
  { author: 'Anonyme', body: 'Mourir est bien peu de chose quand on continue à vivre dans le cœur des autres.' },
];

export const SPANISH_VERSES_AND_POEMS = [
  {
    author: 'Sri Aurobindo',
    body: 'Cada victoria sobre ti mismo significa nueva fuerza para ganar otras victorias.',
  },
  {
    author: 'Epicteto Frigia',
    body: 'La fuente de todas las miserias para el hombre no es la muerte, sino el miedo a la muerte.',
  },
  { author: 'D.K., Tibetano', body: 'Las formas se van, la vida permanece.' },
  {
    author: 'Paulo Coehlo',
    body: 'Cuando menos lo esperamos, la vida nos coloca delante un desafío<br>que pone a prueba nuestro coraje y nuestra voluntad de cambio.',
  },
  {
    title: 'Cuando yo muera',
    author: 'Rosagranaina',
    body: 'Cuando yo muera<br>quisiera que cantaras<br>sobre mi cuerpo frío<br>tus bailes de rumbas. <br><br>No derrames lágrimas. <br>Canta, ríe, baila con una sonrisa. <br>Pienso, será lo mejor para mí<br>que mi corazón reciba de ti. <br><br>Que en ti queden mis alegrías, <br>que en ti se queden mis bailes. <br>Por eso cuando yo muera, <br>no llores por mí. <br>No me lleves rosas ni coronas ni laureles, <br>sólo rézame, cántame, báilame. <br><br>Que los ángeles oigan cómo te canto. <br>No dejes de tocar mi guitarra. <br>Desde el cielo escucharé su sonido. <br>No olvides decirme olé, olé y olé. <br><br>Cuando yo me muera, no llores por mí<br>regáleme tu sonrisa. No me regales flores. <br>Sé muy feliz',
  },
  {
    title: 'Vive la vida',
    author: 'Luis de Cuenca',
    body: 'Vive la vida. Vívela en la calle<br>y en el silencio de tu biblioteca.<br>Vívela en los demás, que son las únicas<br>pistas que tienes para conocerte.<br>Vive la vida en esos barrios pobres<br>hechos para la droga y el desahucio<br>y en los grises palacios de los ricos.<br>Vive la vida con sus alegrías<br>incomprensibles, con sus decepciones<br>(casi siempre excesivas), con su vértigo.<br>Vívela en madrugadas infelices<br>o en mañanas gloriosas, a caballo<br>por ciudades en ruinas o por selvas<br>contaminadas o por paraísos, <br>sin mirar hacia atrás. <br>Vive la vida.',
  },
  {
    title: 'Cuando Tenga Que Dejarte',
    body: 'Cuando tenga que dejarte por un corto tiempo,<br>por favor no te entristezcas, ni derrames lágrimas,<br>ni te abraces a tu pena a través de los años;<br>por el contrario, empieza de nuevo con valentía<br>y con una sonrisa por mi memoria<br>y en mi nombre vive tu vida<br>y haz todas las cosas igual que antes.<br><br>No alimentes tu soledad<br>con días vacíos, sino llena cada hora<br>de manera útil. <br>Extiende tu mano para confortar<br>y dar ánimo, y en cambio<br>yo te confortaré y te tendré cerca de mi:<br>y nunca, nunca, tengas miedo de morir<br>porque yo estaré esperándote en el cielo!',
  },
  { author: 'Anónimo', body: 'En la vida, lo difícil se consigue y lo imposible se intenta' },
  {
    author: 'Henry van Dyke',
    body: 'Alégrate de la vida porque ella te<br>da la oportunidad de amar,<br>de trabajar, de jugar y de mirar<br>a las estrellas.',
  },
];
