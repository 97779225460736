import { BrowserStorageService } from '@/services/BrowserStorageService';

export default {
  getShoppingCartData: (state) => {
    return state.shoppingCartItems;
  },
  getShoppingCartItemCount: (state) => {
    return state.shoppingCartItems.reduce((accumulator, value) => {
      return accumulator + value.quantity;
    }, 0);
  },
  getShoppingCartSubtotal: (state) => {
    // TODO: replace this with an API call to calculate subtotal
    return state.shoppingCartItems
      .map((item) => item.quantity * item.bookCost)
      .reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);
  },
  getPartialBookOrder: (state) => {
    if (state.bookOrderQueue[0]) {
      return state.bookOrderQueue[0];
    } else {
      const partialBookOrderFromLocalStorage = BrowserStorageService.getLocalStorageItemByKey('partial-book-order');
      return partialBookOrderFromLocalStorage;
    }
  },
};
