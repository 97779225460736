import { ApiService } from './ApiService';

export class StoryInfoService {
  static async getStoryInfoByCmiId(cmiId) {
    const apiUrl = process.env.VUE_APP_API_URL ?? '';
    const { storyId, name, primaryPhotoUrl, lastThemeOrderedId, isPromoCodeToggleOn } = await ApiService.get(
      `${apiUrl}/api/StoryInfo/${cmiId}`
    );

    return {
      storyId,
      decedentName: name,
      primaryImage: primaryPhotoUrl,
      lastThemeOrderedId,
      isPromoCodeToggleOn,
    };
  }
}
