import { LoggingService } from '@memllc/component-library';
import { i18n } from '@/localization';
import { AnalyticsService } from './AnalyticsService';

export class LocalizationService {
  static localize(textKey, pluralCount, replacements, forceLocale) {
    if (!textKey) {
      LoggingService.warning(`LocalizationService.localize() - no textKey provided`);
      AnalyticsService.analyticsException(`Localization attempted without key.`, false);
      return '';
    }

    if (forceLocale) {
      forceLocale = { locale: forceLocale };
    }

    let localizedText;

    const pascalTextKey = textKey.replace(/^\w/, (c) => c.toUpperCase());

    if (i18n.global.te(pascalTextKey)) {
      if (pluralCount) {
        localizedText = i18n.global.t(pascalTextKey, pluralCount, replacements, forceLocale);
      } else {
        localizedText = i18n.global.t(pascalTextKey, replacements, forceLocale);
      }

      localizedText = localizedText.replace(/\[/g, '<').replace(/\]/g, '>');

      return localizedText;
    }

    LoggingService.warning(`${textKey} does not exist in locale messages`);
    AnalyticsService.analyticsException(
      `Localization missing translation. Key: ${textKey}, Language: ${i18n.global.locale.value}`,
      false
    );
    return textKey;
  }

  static getLocale() {
    let i18nLocale = i18n.global.locale;
    return i18nLocale;
  }

  static setLanguageAttribute() {
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
      const languageToUse = i18n.global.locale.value;
      htmlElement.setAttribute('lang', languageToUse);
    }
  }
}
