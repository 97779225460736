import { colorValueToRGB } from '@/utilities/colorValueToRGB';
import { ApiService } from './ApiService';

export class ThemeService {
  static async getStoryBookThemes() {
    const apiUrl = process.env.VUE_APP_API_URL ?? '';

    const data = await ApiService.get(`${apiUrl}/api/StoryBookThemes`);

    let storyBookThemes = [];

    data.map((theme) => {
      const storyBookThemeDTO = {
        id: theme.id,
        nameKey: theme.nameKey,
        primaryColorValue: colorValueToRGB(theme.primaryColorValue),
        primaryColorName: theme.primaryColorName,
        secondaryColorValue: colorValueToRGB(theme.secondaryColorValue),
        secondaryColorName: theme.secondaryColorName,
        tertiaryColorValue: colorValueToRGB(theme.tertiaryColorValue),
        tertiaryColorName: theme.tertiaryColorName,
        nameFontNameKey: theme.nameFontNameKey,
        bodyFontNameKey: theme.bodyFontNameKey,
        poems: [],
      };

      theme.poems.map((poem) => {
        const storyBookVerseDTO = {
          title: poem.title,
          author: poem.author,
          body: poem.body,
        };

        storyBookThemeDTO.poems.push(storyBookVerseDTO);
      });

      storyBookThemes.push(storyBookThemeDTO);
    });

    return storyBookThemes;
  }
}
