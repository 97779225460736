import { createStore } from 'vuex';
import applicationModule from './application';
import shoppingCartModule from './shoppingCart';
import storyModule from './story';

export const STORE_NAMESPACES = {
  APPLICATION: 'application',
  SHOPPINGCART: 'shoppingCart',
  STORY: 'story',
};

export default createStore({
  modules: {
    [STORE_NAMESPACES.APPLICATION]: applicationModule,
    [STORE_NAMESPACES.SHOPPINGCART]: shoppingCartModule,
    [STORE_NAMESPACES.STORY]: storyModule,
  },
});
