export class StringService {
  //TODO: move this updated method into component library
  static preventWidowedWord(text) {
    // this function is to help prevent text from only having one word on the last line of text
    let returnValue = '';

    const placeholderForSpaceInHTML = '!@#$%^&*()';

    // detect any HTML with spaces in it, replace space with temporary placeholder
    const regex = /<[^>]*\s[^>]*>/gi;

    while (regex.exec(text) != null) {
      let match = text.match(regex);
      match.forEach((m) => {
        text = text.replace(regex, m.replace(' ', placeholderForSpaceInHTML));
      });
    }

    // make array of words
    const words = text.split(' ');

    // inject &nbsp; between last two words, rebuild string
    if (words.length > 1) {
      words[words.length - 2] += '&nbsp;' + words[words.length - 1];
      words.pop();
      returnValue = words.join(' ');
    }

    // replace placeholders with spaces
    returnValue = returnValue.replaceAll(placeholderForSpaceInHTML, ' ');

    return returnValue;
  }

  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static breakAfterEachWord(string) {
    const words = string.split(' ');

    if (words.length > 1) {
      const stringWithLineBreaks = string.replace(/ /g, '</br>');
      return stringWithLineBreaks;
    }

    return string;
  }

  static removeHTML(string) {
    if (!string) {
      return string;
    }

    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = string;

    return tempDiv.textContent.trim();
  }

  static isNullOrWhitespace(string) {
    if (string && string.trim() !== '') {
      return false;
    }

    return true;
  }

  static encodeHTML(string) {
    var spn, txt;
    spn = document.createElement('span');
    txt = document.createTextNode('');
    spn.appendChild(txt);
    txt.data = string;
    return spn.innerHTML;
  }

  static sanitizeTextInput(string) {
    let returnString = '';
    returnString = string.replace(/^\s+|\s+$/gm, '');
    if (this.isNullOrWhitespace(returnString)) return returnString;
    returnString = this.encodeHTML(returnString);

    return returnString;
  }
}
