<template>
  <div class="shopping-cart-icon">
    <BaseIcon name="ShoppingCart_white" height="100%" width="100%" />
    <div
      class="shopping-cart-icon__counter"
      :class="{
        'shopping-cart-icon__counter--small-text': isShoppingCartLargeNumber && !isShoppingCartTooHighForIcon,
        'shopping-cart-icon__counter--smaller-text': isShoppingCartTooHighForIcon,
      }"
      v-if="!isShoppingCartEmpty"
    >
      {{ displayShoppingCartCount }}
    </div>
  </div>
  &nbsp;{{ LocalizationService.localize('Cart') }}
</template>

<script>
import { BaseIcon } from '@memllc/component-library';
import { mapGetters } from 'vuex';
import { LocalizationService } from '@/services/LocalizationService';
import { STORE_NAMESPACES } from '@/store';

export default {
  name: 'ShoppingCartIcon',
  components: {
    BaseIcon,
  },
  data() {
    return {
      LocalizationService,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACES.SHOPPINGCART, ['getShoppingCartItemCount']),
    shoppingCartItemCount() {
      return this.getShoppingCartItemCount;
    },
    isShoppingCartTooHighForIcon() {
      return this.shoppingCartItemCount > 999;
    },
    isShoppingCartLargeNumber() {
      return this.shoppingCartItemCount > 99;
    },
    isShoppingCartEmpty() {
      return this.shoppingCartItemCount < 1;
    },
    displayShoppingCartCount() {
      return this.isShoppingCartTooHighForIcon ? '999+' : this.shoppingCartItemCount;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/assets/scss/tools' as t;
@use '@/assets/scss/variables' as v;

.shopping-cart-icon {
  $shopping-cart-icon-size: 4rem;

  @include t.media-query(max-width, v.$bp-xl) {
    $shopping-cart-icon-size: 3.5rem;
  }

  max-height: $shopping-cart-icon-size;
  max-width: $shopping-cart-icon-size;
  display: grid;

  * {
    grid-column-start: 1;
    grid-row-start: 1;
    justify-self: center;
    align-self: center;
  }

  &__counter {
    $shopping-cart-counter-size: 2rem;

    height: $shopping-cart-counter-size;
    width: $shopping-cart-counter-size;
    font-size: v.$font-size-xs;
    font-weight: bold;
    background-color: t.get-system-color('honey');
    color: white;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: start;
    justify-self: start;
    margin-left: 0.35rem;
    letter-spacing: 0;
    margin-top: -2px;

    &--small-text {
      font-size: v.$font-size-xxs;
    }

    &--smaller-text {
      font-size: calc(v.$font-size-xxs - 0.1rem);
    }
  }
}
</style>
