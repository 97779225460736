export default {
  getStoryData: (state) => {
    return {
      storyId: state.storyId,
      name: state.decedentName,
      primaryImage: state.primaryImage,
      lastThemeOrderedId: state.lastThemeOrderedId,
      isPromoCodeToggleOn: state.isPromoCodeToggleOn,
    };
  },
};
