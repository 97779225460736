import { BrowserStorageService } from '@/services/BrowserStorageService';
import { SERVICE_CONSTANTS } from '@/services/Constants';

export default {
  REMOVE_SHOPPING_CART_ITEM: (state, uniqueId) => {
    state.shoppingCartItems = state.shoppingCartItems.filter((item) => item.uniqueId !== uniqueId);
  },
  SET_QUANTITY_VALUE: (state, quantityChangeObj) => {
    const shoppingCartItem = state.shoppingCartItems.find((item) => item.uniqueId === quantityChangeObj.uniqueId);
    shoppingCartItem.quantity = quantityChangeObj.quantity;
  },
  QUEUE_BOOK_ORDER: (state, order) => {
    state.bookOrderQueue.push(order);
    BrowserStorageService.writeNewLocalStorageItemWithExpiration('partial-book-order', order);
  },
  DEQUEUE_BOOK_ORDER: (state) => {
    state.bookOrderQueue.shift();
    BrowserStorageService.deleteLocalStorageItemByKey('partial-book-order');
  },
  ADD_ITEM_TO_SHOPPING_CART: (state, item) => {
    state.shoppingCartItems.push(item);

    const localStorageCartKey = SERVICE_CONSTANTS.LOCAL_STORAGE_KEYS.SHOPPING_CART;

    BrowserStorageService.pushOntoLocalStorageArrayByKey(localStorageCartKey, item);
  },
  SYNC_CART_ITEMS_WITH_LOCAL_STORAGE: (state, localStorageCart) => {
    state.shoppingCartItems = localStorageCart;
  },
  EDIT_SHIPPING_ADDRESS_BY_ID: ({ shoppingCartItems }, { uniqueId, newAddress }) => {
    shoppingCartItems.forEach((item) => {
      if (item.uniqueId.toString() === uniqueId) {
        item.shippingAddress = newAddress;
      }
    });
  },
};
