<template>
  <div class="carousel-thumbnail" :data-qa="`carousel-thumbnail`">
    <Swiper
      @swiper="onSwiper"
      :breakpoints="breakpointsObj"
      :slides-per-view="4"
      :space-between="10"
      :modules="modules"
      watch-slides-progress
    >
      <SwiperSlide v-for="(image, index) in images" :key="image.src" @click.stop="$emit('update:currentSlide', index)">
        <div class="thumbnail-wrapper" :data-qa="`carousel-thumbnail-image-${index}`">
          <img :src="image.src" :alt="image.alt" />
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from '@SwiperVue';

export default {
  name: 'CarouselThumbnail',
  props: {
    currentSlide: {
      type: Number,
    },
    images: {
      type: Array,
      required: true,
    },
    thumbs: Function,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  emits: ['swiper', 'update:currentSlide'],

  setup(props, { emit }) {
    const onSwiper = (swiper) => {
      emit('swiper', swiper);
    };

    const breakpointsObj = {
      320: {
        slidesPerView: 4,
      },
      480: {
        slidesPerView: 6,
      },
    };

    return {
      onSwiper,
      modules: [props.thumbs],
      breakpointsObj,
    };
  },
};
</script>

<style lang="scss">
@use '@/assets/scss/variables' as v;

.carousel-thumbnail {
  margin-top: 1rem;
  user-select: none;

  .swiper-wrapper {
    display: grid;
    // In order for the layout to work the number of columns must match the slides-per-view attribute on <swiper/>
    // Likewise, the gap size must match the space-between attribute. SwiperJs injects an inline width style based on
    // the two attributes just mentioned.
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1rem;
    width: 100%;

    @media (min-width: v.$bp-sm) {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }
  }

  .thumbnail-wrapper {
    height: 100%;

    img {
      display: block;
      width: 90%;
      height: 90%;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }
}
</style>
