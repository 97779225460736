<template>
  <div class="search-bar">
    <form class="search-bar__wrapper" @submit.prevent="submit(inputText)">
      <input
        class="search-bar__input"
        type="text"
        :aria-label="labelText"
        v-model="inputText"
        @input="cmiIdUpdate"
        :placeholder="LocalizationService.localize('StoryId')"
        autocomplete="on"
        inputmode="numeric"
        maxlength="10"
      />
      <button type="submit" class="search-bar__submit" aria-label="Search for Story">
        <BaseIcon name="Arrow_Icon_right_white" />
      </button>
    </form>
  </div>
</template>

<script>
import { BaseIcon } from '@memllc/component-library';
import { AnalyticsService } from '@/services/AnalyticsService';
import { BrowserStorageService } from '@/services/BrowserStorageService';
import { LocalizationService } from '@/services/LocalizationService';
import { StoryInfoService } from '@/services/StoryInfoService';

export default {
  name: 'StoryIDSearch',
  components: {
    BaseIcon,
  },
  props: {
    labelText: {
      type: String,
    },
    implementation: {
      type: String,
    },
  },
  data() {
    return {
      inputText: '',
      isLargeScreenSize: window.innerWidth >= 1200,
      LocalizationService,
    };
  },
  methods: {
    async submit(storyId) {
      if (storyId === '') return;

      try {
        await StoryInfoService.getStoryInfoByCmiId(storyId);
        AnalyticsService.analyticsEvent('search_for_story', 'ecommerce', undefined, this.implementation);
      } catch ({ message }) {
        if (message === '404') {
          this.$router.push('/story-not-found');
          AnalyticsService.analyticsEvent('no_story_found', 'ecommerce', undefined, this.implementation);
        } else {
          this.$router.push('/error');
        }

        return;
      }

      const existingShoppingCartItems = BrowserStorageService.getLocalStorageItemByKey('shopping-cart');
      if (!existingShoppingCartItems || existingShoppingCartItems.length === 0) {
        this.$router.push(`/select-story-book/${storyId}`);
      } else {
        const existingStoryId = existingShoppingCartItems[0].storyId;
        if (existingStoryId.toString() === storyId.toString()) {
          this.$router.push(`/select-story-book/${storyId}`);
        } else {
          this.$router.push(`/confirm-story-change/${storyId}`);
        }
      }
    },
    preventPaste(event) {
      event.preventDefault();
    },
    cmiIdUpdate(event) {
      let returnString = this.inputText;
      if (!event.data) return returnString;
      let val = event.data;
      const isInputValid = /^[0-9]*$/.test(val);

      if (!isInputValid) {
        const indexOfInvalidCharacter = returnString.toString().indexOf(val);
        returnString = returnString.slice(0, indexOfInvalidCharacter);
      }

      this.inputText = returnString;
      return;
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.isLargeScreenSize = window.innerWidth >= 1200;
    });
  },
};
</script>

<style lang="scss" scoped>
@use '@/assets/scss/tools' as t;
@use '@/assets/scss/variables' as v;

.search-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 0;

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    width: 100%;
    max-width: 100%;

    .search-bar--in-header & {
      @include t.media-query(min-width, v.$bp-xl) {
        margin-top: 0;
      }
    }
  }

  &__input {
    @include t.nav-flyout-text;
    font-weight: bold;
    color: t.get-system-color('teal');
    width: 100%;
    max-width: 100%;
    font-family: v.$font-stack-primary;
    letter-spacing: 0.3rem;
    // padding: t.space('lg') t.space('xxxl') t.space('lg') t.space('lg');
    padding: t.space('lg') t.space('xxl') t.space('lg') t.space('sm');
    border: 1px solid t.color('silver');

    @include t.media-query(min-width, v.$bp-xl) {
      padding: t.space('lg') t.space('xxxl') t.space('lg') t.space('lg');
    }

    &::placeholder {
      @include t.nav-flyout-text;
      font-size: 1.2rem;
      color: t.color('silver', -2);

      @include t.media-query(min-width, v.$bp-xl) {
        font-size: 1.4rem;
      }
    }

    .search-bar--in-header & {
      @include t.media-query(min-width, v.$bp-xl) {
        // padding: 0.3rem;
        // padding-right: 3.5rem;
        // padding-left: 1rem;
        padding: 0.75rem t.space('xxl') 0.75rem 0.75rem;
        //font-size: 2rem;
        // font-weight: normal;
        letter-spacing: 0.2rem;
      }
    }
  }

  &__submit {
    @include t.unbuttonize;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    & svg {
      fill: t.get-system-color('turquoise');
      height: 3rem;
      width: 3rem;

      .search-bar--in-header & {
        @include t.media-query(min-width, v.$bp-xl) {
          height: 2.25rem;
          width: 2.25rem;
        }
      }
    }

    &:active {
      background-color: t.get-system-color('honey');
      outline: 1px solid t.get-system-color('honey');

      & svg {
        fill: white;
      }
    }

    &:focus-visible {
      background-color: t.get-system-color('honey');
      outline: 1px solid t.get-system-color('honey');

      & svg {
        fill: white;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
