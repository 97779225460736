<template>
  <div class="image-carousel" data-testid="carousel-test" :data-qa="`image-carousel`">
    <swiper
      @click="onClick"
      @swiper="onSwiper"
      @slideChange="updateIndex"
      :speed="speed"
      :slides-per-view="1"
      :navigation="navigation"
      :pagination="pagination ? { clickable: true } : false"
      :modules="modules"
      :thumbs="{ swiper: thumbsRef }"
      :autoplay="autoPlayOptions"
      watchSlidesProgress
      effect="fade"
      :fadeEffect="{
        crossFade: true,
      }"
      :style="{
        maxHeight,
      }"
    >
      <swiper-slide v-for="(image, index) in currentImages" :key="image.src">
        <div class="image-wrapper" :data-qa="`image-carousel-image-${index}`">
          <img :alt="image.alt" :src="image.src" :srcset="image.srcSet" :sizes="sizes" :style="{ objectFit }" />
        </div>
      </swiper-slide>
    </swiper>
    <span v-if="caption" class="image-carousel__caption" :class="{ 'image-carousel__caption--centered': !thumbnail }">{{
      caption
    }}</span>
  </div>
  <CarouselThumbnail
    @swiper="onThumbSwiper"
    v-show="thumbnail"
    :thumbs="Thumbs"
    v-model:currentSlide="currentSlide"
    :images="currentImages"
  />
</template>

<script>
import { Swiper, SwiperSlide } from '@SwiperVue';
import { Thumbs, FreeMode, Navigation, Pagination, A11y, Autoplay, EffectFade } from 'swiper';
import { ref, toRefs, watch } from 'vue';
import { LocalizationService } from '@/services/LocalizationService';
import CarouselThumbnail from './CarouselThumbnail.vue';
import '@SwiperBundleCss';

export default {
  name: 'ImageCarousel',
  emits: ['imageSelected'],
  components: {
    Swiper,
    SwiperSlide,
    CarouselThumbnail,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    selectedImageIndex: {
      type: Number,
    },
    pagination: {
      type: Boolean,
    },
    navigation: {
      type: Boolean,
    },
    thumbnail: {
      type: Boolean,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    speed: {
      type: Number,
      default: 100,
    },
    sizes: {
      type: String,
    },
    objectFit: {
      type: String,
      default: 'cover',
    },
    maxHeight: {
      type: String,
      default: '51rem',
    },
    sendEmit: {
      type: Boolean,
      default: false,
    },
    caption: {
      type: String,
    },
  },
  data() {
    return {
      currentSlideIndex: 0,
      LocalizationService,
    };
  },
  setup(props) {
    const { selectedImageIndex, autoplay } = toRefs(props);
    const currentSlide = ref(0);
    const swiperRef = ref(null);
    const thumbsRef = ref(null);
    const autoPlayOptions = ref({});

    watch(currentSlide, () => {
      if (swiperRef.value !== null) {
        swiperRef.value.slideTo(currentSlide.value, 100);
      }
    });

    watch(selectedImageIndex, () => {
      if (swiperRef.value !== null) {
        currentSlide.value = selectedImageIndex.value;
      }
    });

    const onSwiper = (swiper) => {
      swiperRef.value = swiper;
    };

    const onThumbSwiper = (swiper) => {
      thumbsRef.value = swiper;
    };

    let modules = [FreeMode, Navigation, Thumbs, Pagination, A11y, EffectFade];

    if (autoplay.value) {
      modules = [...modules, Autoplay];

      autoPlayOptions.value = {
        delay: 8000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      };
    }

    return {
      autoPlayOptions,
      swiperRef,
      onSwiper,
      onThumbSwiper,
      currentSlide,
      thumbsRef,
      Thumbs,
      modules,
    };
  },
  computed: {
    currentImages() {
      return this.images;
    },
  },
  methods: {
    updateIndex(value) {
      this.currentSlideIndex = value.realIndex;
    },
    onClick() {
      if (this.sendEmit) {
        this.$emit('imageSelected', this.currentSlideIndex);
      }
    },
  },
};
</script>

<style lang="scss">
@use '@/assets/scss/tools' as t;
@use '@/assets/scss/variables' as v;

.image-carousel {
  width: 100%;
  user-select: none;

  .image-wrapper {
    img {
      display: block;
      width: 100%;
      height: var(--image-carousel-height);
    }
  }

  &__caption {
    font-family: v.$font-stack-secondary;
    font-size: v.$font-size-md;
    color: t.get-system-color('chocolate');

    &--centered {
      display: flex;
      justify-content: center;
    }
  }

  .swiper-pagination {
    position: static;

    @include t.media-query(min-width, v.$bp-xl) {
      margin-bottom: 1.5rem;
    }
  }

  .swiper-pagination-bullet {
    width: 1.2rem !important;
    height: 1.2rem !important;
    margin-left: 0.7rem !important;
    margin-right: 0.7rem !important;

    &:hover {
      opacity: 1;
      background-color: t.get-system-color('turquoise', -1);
    }
  }

  .swiper-pagination-bullet-active {
    background-color: t.get-system-color('turquoise');
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: t.get-system-color('white');
  }
}
</style>
