import { event, exception, purchase } from 'vue-gtag';

export class AnalyticsService {
  static analyticsEvent(action, category, value, label) {
    event(action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
  static analyticsException(description, fatal) {
    exception({
      description,
      fatal,
    });
  }

  static trackPurchase(data) {
    purchase(data);
  }
}
