<template>
  <div class="call-us">
    <p class="call-us__text">
      {{ LocalizationService.localize('TalkToARealPerson') }}
      <br class="hideable-break" />
      {{ LocalizationService.localize('WeAreHere') }}
    </p>
    <a
      class="call-us__phone-number"
      href="tel:18885494636"
      :aria-label="`${LocalizationService.localize('TalkToARealPerson')} ${LocalizationService.localize(
        'WeAreHere'
      )} 1 8 8 8. 5 4 9. 4 6 3 6.`"
      @click="trackCallUsClick()"
    >
      1-888-549-4636</a
    >
  </div>
</template>

<script>
import { AnalyticsService } from '@/services/AnalyticsService';
import { LocalizationService } from '@/services/LocalizationService';

export default {
  name: 'CallUs',
  data() {
    return {
      LocalizationService,
    };
  },
  methods: {
    trackCallUsClick() {
      AnalyticsService.analyticsEvent('call_us_click', 'engagement', undefined, 'Phone Number on Home Page clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../assets/scss/variables' as v;
@use '../assets/scss/tools' as t;

.call-us {
  color: t.get-system-color('silver', -1);
  font-family: v.$font-stack-secondary;
  // Not using font size variable for this as this is a unique instance where this font-size is required.
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
  text-align: center;
  width: min-content;
  margin: t.space('xxl') auto;

  @media (max-width: v.$bp-xs) {
    width: auto;
  }

  @media (min-width: v.$bp-md) {
    font-size: 2.7rem;
  }

  @media (min-width: v.$bp-xl) {
    font-size: 2.5rem;
    margin-bottom: t.space('xxxl');
    text-transform: none;
    font-family: v.$font-stack-secondary;
    width: auto;
  }

  &__text {
    line-height: 1;
  }

  &__phone-number {
    font-family: v.$font-stack-tertiary-small-caps;
    color: t.get-system-color('teal');
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 3.6rem;
    text-decoration: none;
    font-size: v.$font-size-xl;
    margin-top: 0.25rem;
    white-space: nowrap;
    letter-spacing: 2.2px;
    line-height: t.space('xxl');

    @media (max-width: v.$bp-xs) {
      font-size: v.$font-size-md;
      max-height: none;
      white-space: unset;
    }

    @media (min-width: v.$bp-lg) {
      font-size: 5rem;
      margin-top: 2rem;
    }

    @media (min-width: v.$bp-xl) {
      margin-top: 0;
    }
  }

  &__icon {
    transform: rotate(90deg);
    margin-right: 1rem;
  }
}

svg {
  fill: t.get-system-color('butter');
  height: 3rem;
  width: 3rem;

  @media (min-width: v.$bp-md) {
    height: 4rem;
    width: 4rem;
  }
}
</style>
