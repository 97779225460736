import { createApp } from 'vue';
import VueGtagPlugin from 'vue-gtag';
import VueTelInput from 'vue-tel-input';
import { i18n } from '@/localization';
import App from './App.vue';
import router from './router';
import { RoutingService } from './services/RoutingService';
import store from './store';
import 'vue-tel-input/dist/vue-tel-input.css';

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(
    VueGtagPlugin,
    {
      pageTrackerTemplate(to) {
        return {
          page_title: RoutingService.getPageTitleForAnalytics(to),
          page_path: to.path,
        };
      },
      config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID, send_page_view: true },
      enable: true,
      pageTrackerEnabled: true,
      pageTrackerExcludedRoutes: ['redirect'],
    },
    router
  )
  .use(VueTelInput)
  .mount('#app');
